<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div slot="header">
      <h5>{{ $route.meta.title }}</h5>
    </div>

    <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
      <div class="row">
        <div class="col-md-8">
          <el-form-item label="旧密码" prop="oldpassword">
            <el-input
              v-model="formData.oldpassword"
              type="password"
              placeholder="请输入旧密码"
              autocomplete="off"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              v-model="formData.password"
              type="password"
              placeholder="请输入新密码"
              autocomplete="off"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="repassword">
            <el-input
              v-model="formData.repassword"
              type="password"
              placeholder="请输入确认新密码"
              @keyup.enter.native="submitForm"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-12"></div>
      </div>
      <div class="row mt-20">
        <div class="col-md-8">
          <div class="text-center">
            <el-button type="success" @click="submitForm()">保存</el-button>
          </div>
        </div>
      </div>
    </el-form>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      coverDialogVisible: false,
      formData: {
        oldpassword: "",
        password: "",
        repassword: "",
      },
      rules: {
        oldpassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入密码"));
              } else if (this.$utils.checkPasswordStrong(value) < 2) {
                callback(new Error("密码必须5位以上，且必须包含两种字符以上"));
              } else {
                if (this.formData.repassword !== "") {
                  this.$refs.elForm.validateField("repassword");
                }
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.formData.password) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.formData = {
      nickname: this.user.nickname,
      gender: this.user.gender,
    };
  },
  mounted() {},
  methods: {
    cropImage() {
      this.$refs.imageCroper.crop();
    },
    uploadImage(file) {
      if (!file) {
        return;
      }
      let param = new FormData(); //创建form对象
      param.append("attach", file, "crop.jpg"); //通过append向form对象添加数据

      this.$api.ucenter.User.avatar(param).then((res) => {
        res.data.avatar = res.data.avatar
          ? res.data.avatar + "?r=" + this.$utils.getID()
          : null;

        this.$store.dispatch("setUserInfo", res.data);

        this.coverDialogVisible = false;
      });
    },

    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;

        // TODO 提交表单
        this.loading = true;
        this.$api.ucenter.User.saveUserInfo(this.formData)
          .then((res) => {
            this.$store.dispatch("setUserInfo", res.data.user);
            this.loading = false;

            this.$utils.success("保存成功");
          })
          .catch((err) => {
            console.log("err:", err);
            this.loading = false;
          });
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.pofile-avatar {
  width: 100px;
  height: 100px;
}
</style>
